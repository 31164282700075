body {
  font-family: 'Ropa Sans', sans-serif;  
  overscroll-behavior-y: none;

}

*:focus {
  outline: none;
}

#page {
  position:relative;
  overflow: hidden;
}

$primary-color: #384967;
$secondary-color: #70A8D5;

  
a:hover {
  color: #171F30;
}

.en .nav-en, .sc .nav-sc {
  display: none;
}

.ropa {
  font-family: 'Ropa Sans', sans-serif;  
}

h1, h2, h3, h4 {
  font-family: 'Playfair Display', serif;

}

h1 {
  font-size: 22px;
  margin-bottom: 25px;

  @include breakpoint(large) {
    font-size: 24px;
  }
}

h2 {
  font-size: 20px;
  color: #384967;
  letter-spacing: 0.5px;
  line-height: 1;
  margin-bottom: 25px; 

  @include breakpoint(large) {
    font-size: 30px;
  }
}

h3 {
  font-size: 25px;
}

h4 {
  font-size: 17px;
  margin-bottom: 20px;
  line-height: 1;
}

p {
  font-size: 15px;
  // color: $secondary-color;
  letter-spacing: 0.58px;  
  line-height: 1.7;
  margin-bottom: 25px;

  @include breakpoint(large) {
    font-size: 16px;
  }
}

p:last-of-type {
  // margin-bottom: 0;
}

p.large {
  font-size: 18px;
  line-height: 1.3;

  @include breakpoint(large) {
    font-size: 22px;
  }
}
.playfair {
  font-family: 'Playfair Display', serif;
}

.no-margin {
  margin: 0;
}

.bold {
  font-weight: 600;
}

.primary {
  color: $primary-color!important;
}

.bg-primary {
  background-color: $primary-color!important;
}

.bg-light-grey {
  background-color: rgba(50,50,50,0.1);;
}

.secondary {
  color: $secondary-color!important;
}

.link {
  font-size: 12px;
  margin-top: 18px;
  border-bottom: 1px solid $primary-color;
  padding-bottom: 2px;
}

.right {
  float:right;
}

.overflow-visible {
  overflow:visible!important;
}

.padding-side {
  padding: 5% 0.825rem!important;

  @include breakpoint(large) {
    padding: 5% 11% 8% 6%!important;
  }
}

.padding-side2 {
  padding: 5% 0.825rem!important;

  @include breakpoint(large) {
    padding: 5% 11% 0% 6%!important;
  }
}

.section-break {
  position:relative;
  text-align: center;
  span {
    font-family: 'Playfair Display', serif;
    font-size: 18px;
    color: $primary-color;    
    background-color:white;
    padding: 0 40px;
    display: inline-block;
    z-index: 2;
    position:relative;
    font-weight: 500;

    @include breakpoint(large) {
      font-size: 26px;
    }
  }

  &:after {
    content: "";
    position:absolute;
    border-bottom: 1px solid $secondary-color;
    top: 40%;
    width: 100%;
    left: 0;
  }
}

.padding-around {
  padding: 7% 7%!important;

  @include breakpoint(large) {
    padding: 10%!important;
  }
}

.row {
  overflow: hidden;
}

.spacing20 {
  height: 20px;
}

.spacing40 {
  height: 40px;
}

.spacing60 {
  height: 20px;
  @include breakpoint(large) {
    height: 60px;
  }
}

.spacing80 {
  height: 20px;
  @include breakpoint(large) {
    height: 80px;
  }
}

.spacing100 {
  height: 20px;
  @include breakpoint(large) {
    height: 100px;
  }
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 110px;
  @include breakpoint(large) {
    top: 60px;
    left: 9%;
    width: 160px;
  }
}
.hidden {
  opacity: 0;
}
nav {
  position: fixed;
  z-index: 99;
  width: 60px; 
  text-align: center;
  top: 0;
  right: 0;
  height: 100vh;
  
  &.min {
    height: 40vh;
  }

  .close {
    color: white;
    display: none;
    cursor: pointer;
    position:absolute;
    right: 15px;
    top: 12px;
    font-size: 25px;
    line-height: 1;
  }

  background-color:transparent;
  transition: all 0.3s ease;

  @include breakpoint(large) {    
    background-color:white; 
    width: 100px; 
  }

  .hidden {
    pointer-events:none;
    transition-delay: 0.2s;
  }

  .menu-toggle {
    position: absolute;
    top: 20px;
    left: auto;
    right: auto;
    margin: 0 auto;
    z-index: 9;   
    padding: 7px;
    background-color: white; 

    @include breakpoint(large) {
      top: 50px;
      left: 0;
      right: 0;

    }
  }
  .menu-toggle a {  
    font-size: 16px;
    
    color: black;
    letter-spacing: 0.5px;
    text-align: center;
    display: none;

    @include breakpoint(large) {    
      color: #384967;
      display: block;
    }
  }

  .nav-logo {
    margin-top: 60px;
    margin-bottom: 60px;
    display: block;
    opacity: 0;  
    transition: all 0.3s ease;  
    @include breakpoint(large) {
    
    }   
  }

  .nav-sign {
    position:absolute;
    bottom: 30px;
    left: 30px;

    p {
      padding-right: 30px;
      font-size: 13px;
    }
  }

  .nav-item {    
    
    @include breakpoint(large) {
     
    }

    a {
      font-size: 20px;      
      display: block;
      line-height: 1;
      letter-spacing: 1px;
      color: white;
      margin-bottom: 15px;
      &:hover {
        color: $secondary-color;
      }
      

      @include breakpoint(large) {        
        font-size: 15px;
      }
    }
  }


  a {        
    font-size: 16px;
    // color: #FFFFFF;
    text-align: left;
    text-transform: uppercase;
  }

  .scroll-wrap {
    position:absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .scroll {
    display: block;
  }
  .scroll-img {
    width: 20%;
  }

  &.open {

    .close {
 
      display: block;
    }

    .hidden {
      pointer-events:auto;
    }

    .menu-toggle {
      opacity: 0;
      z-index: -1;
    }

    .hidden {
      opacity: 1;
    }

    text-align: left;
    width: 240px;
    background-color: $primary-color;
    z-index: 5;
    padding: 0 30px;
    height: 100vh;

    .nav-logo {
      opacity: 1;
    }

    .nav-item {
      display: block;
    }

    .scroll-wrap {
      display: none!important;
    }
  }
}

.fade-up {
  transform: translateY(15px);
  opacity: 0;
  transition: 0.9s all ease;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-down {
  transform: translateY(-15px);
  opacity: 0;
  transition: 0.9s all ease;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-left {
  transform: translateX(-15px);
    opacity: 0;
    transition: 0.9s all ease;
    &.active {
      transform: translateX(0);
      opacity: 1;
    }
}

.fade-right {
  transform: translateX(15px);
    opacity: 0;
    transition: 0.9s all ease;
    &.active {
      transform: translateX(0);
      opacity: 1;
    }
}

.fade-delay {
  transition-delay: 0.2s;
}

.fade-delay2 {
  transition-delay: 0.3s;
}

.fade-delay3{
  transition-delay: 0.4s;
}
  
$tcon-size: 24px !default;
$tcon-transition: .3s !default;
$tcon-jstransform: 'tcon-transform' !default;

$tcon-menu-radius: ($tcon-size / 14) !default;
$primary-color: #fff !default;
$tcon-menu-arrowleft-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 90deg)
) !default;
$tcon-menu-arrow360left-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 360deg)
) !default;
.tcon {
  @include breakpoint(large) {
    // display: none;
  }
  margin: 0 auto;
  appearance: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $tcon-size;
  transition: $tcon-transition;
  user-select: none;
  width: $tcon-size;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  > * {
    display: block;
  }

  &:hover,
  &:focus {
    outline: none; // see issue #36 https://github.com/grayghostvisuals/transformicons/issues/36
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin tcon-menu-lines {
  display: inline-block;
  height: 3px;
  width: 28px;
  border-radius: $tcon-menu-radius;
  transition: $tcon-transition;
  background: $primary-color;

  @include breakpoint(large) {
    background: $primary-color;
  }
}

.tcon-menu__lines {
  @include tcon-menu-lines;
  position: relative;

  &::before,
  &::after {
    @include tcon-menu-lines;
    content: '';
    position: absolute;
    left: 0;
    transform-origin: ($tcon-size / 14) center;
    width: 100%;
  }

  &::before {
    top: 7px;
  }

  &::after {
    top: -7px;
  }

  .#{$tcon-jstransform} & {
    transform: scale3d(.8, .8, .8);
  }
}
.tcon-menu--xcross {
  width: auto;

  &.#{$tcon-jstransform} {
    .tcon-menu__lines {
      background: transparent;

      &::before,
      &::after {
        transform-origin: 50% 50%;
        top: 0;
        width: $tcon-size;
      }

      &::before {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &::after {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
// see issue #7 https://github.com/grayghostvisuals/transformicons/issues/7#issuecomment-59813596
.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}


.hero {
  height: 50vh;
  width: 100%;
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center center;
  position:relative;
  z-index: -1;  

  &::after {
    content: '';
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);

  }

  @include breakpoint(large) {
    height: 70vh;     
  }


  h1 {    
    font-size: 22px;    
    font-weight: 500;
    letter-spacing: 0.5px;
    padding-top: 60%;
    text-shadow: 4px 4px 7px #000000;
    position:absolute;
    bottom: 10px;
    left: 5%;

    @include breakpoint(large) {
      font-size: 30px;
      font-weight: 600;
      bottom: 40px;
      left: 9%;
    }

  }
  p {
  
    font-size: 16px;    
    letter-spacing: 0.58px;

  }


}

.home .hero {
  background-image:url(/assets/images/home/header.jpg);
  background-position: center;
  height: 50vh;
  @include breakpoint(large) {
    height: 100vh;
  }

  h1 {        
    padding-top: 60%;    
    @include breakpoint(large) {      
      padding-top: 58%;
    }
  }
}

.about .hero {
  background-image:url(/assets/images/about/header.jpg);
}

.contact .hero {
  background-image:url(/assets/images/contact/header.jpg);
}

.terms .hero {
  background-image:url(/assets/images/contact/header.jpg);
}

.services .hero {
  background-image:url(/assets/images/services/header.jpg);
}

.projects .hero {
  background-image:url(/assets/images/project/header.jpg);
}


.bg-opacity {
  background: rgba(38,49,80,0.8);
}


.show-on-medium {
  display: none;
  @include breakpoint(medium) {
    display: block
  }
}

.v-align-center {
  display: flex;
  align-items: center;
  
  flex-wrap: wrap;

  @include breakpoint(large) {
    flex-wrap: nowrap;
  }

}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.white {
  color: white!important;
}

hr {
  border-bottom: 1px solid $primary-color;
  width: 90%;
  // margin: 80px auto;

  &.full {
    width: 100%;
    margin: 0;
  }

  &.white {
    border-bottom: 1px solid white;
  }
}

.relative {
  position: relative;
}


.button {
  background-color: $secondary-color;
  // border: 1px solid $primary-color;
  border-radius: 5px;
  padding: 7px 50px;
  color: black;
  transition: 0.5s all ease;

  &:hover {
    background-color:white!important;
    color: $primary-color!important;
  }

  &.white {
    background-color: white;
    border: 1px solid $primary-color;
    color: $primary-color!important;

    &:hover {
      background-color: $secondary-color!important;
      color: black!important;
      border: 1px solid $secondary-color;
    }
  }

  &.filled {
    background-color: $primary-color;
    color: white;

    &:hover {
      border: 1px solid $primary-color;
    }

    &.white {
      background-color:white!important;
      color: $primary-color!important;
    }

    &.dark {
      background-color: $secondary-color;
      color: white;
    }
  }

  &.dark {
    color: $secondary-color;
    border: 1px solid $secondary-color;
  }

  &.inline {
    display: inline-block;
    margin-right: 10px;
  }

}



.icon {
  display: block;
  width: 40px;
  margin: 0 auto 20px;
  z-index: 2;
}


footer {}

.incomplete-row {
  .columns {
    float: none!important;
    display: inline-block;
  }
}

.border-right-dark {
  border-right: 1px solid $secondary-color;
}

@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
  .row::before, .row::after {
    display: none!important; 
  }
}

hr.white {
  border-bottom: 1px solid white;
}

.sc .hide-on-sc {
  display: none;
}

.project-tile {
  color: white;
  position: relative;  
  margin-bottom: 20px;  
  display: block;
  &.no-link:hover {
    cursor: auto;
  }
  @include breakpoint(large) {
    pointer-events: default;
  }

  img {
    width: 100%;
  }

  &:after {
    content: '';
    position:absolute;
    left: 0;
    bottom: auto;
    width: 100%;
    
    background-color:rgba(56,73,103,0.85);
    z-index: 1;
    transition: all 0.3s ease;
    

    top: auto;
    bottom: 0;
    height: 100%;
    opacity: 0.8;

    @include breakpoint(large) {
      top: 0;
      height: 100%;
      opacity: 0.3;
    }
  }



  .proj-detail {
    text-align: center;
    position:absolute;
    top: 11%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;

    @include breakpoint(large) {
      top: 50%;
    }

    h1 {
      margin: 0;
      font-size: 15px;
      text-shadow: 1px 1px 5px rgba(0,0,0,0.35);
      font-weight: 600;
      color: white;

      @include breakpoint(large) {
        font-size: 26px;
      }
    }    

    transition: all 0.3s ease;
  }  

  .proj-hidden {
    transition: all 0.3s ease;
    position:absolute;
    top: 18%;    
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    opacity: 1;
    text-align:center;
    color: white;
    strong {
      font-size: 11px;      
      @include breakpoint(large) {
        font-size: 16px;
      }
    }

    p {
      font-size: 11px;
      margin: 0;      
      @include breakpoint(large) {
        font-size: 16px;
      }
    }
    hr {
      border-bottom: 1px solid white;
      width: 100%;
      margin: 0 auto 25px;
      display: none;

      @include breakpoint(large) {
        display: block; 
      }
    }

    @include breakpoint(large) {
      opacity: 0;
      top: 58%;      
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  &:hover {
   .proj-detail {
    top: 13%;

    &.short {
      top: 24%;
    }
   } 

   .proj-hidden {
    top: 56%;
    opacity: 1;
   } 



   &:after {    
     opacity: 1;
   }
  }
}

.form { 
  // background-color: $primary-color;
  text-align: center;

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  select {
    padding-left:15px; 
    margin: 0 auto 30px;
    display: block;
    width: 60%;
    background-color: transparent;
    border-radius: 7px;
    text-align: left;
    font-size: 13px;
    color: black;
  }

  .submit {
    display:block;
    width: 60%;
    margin: 20px auto 0;
  }
}
select {
  color: #000;
  text-align-last:left;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000!important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #000!important;
}

footer {
  padding: 50px 0 0;
  background-color:$primary-color;
  
  .footer-logo {
    width: 100%;
    max-width: 160px;
    display: block;
  }

  p {
    margin-bottom: 0;
  }
}


.social {
  a {    
    display: inline-block;
    background-color:white;
    margin-right: 10px;
    padding: 5px 8px;
    border-radius:50px;
    img {
      width: 18px;
    }
  }
}

.site {
  a {
    display: block;
    color: white;
    margin-bottom: 4px;
  }
}

#smart-form {
  margin-top: 20px;
  input {
    background-color:lighten( $primary-color, 10% );    
    width: 60%;
    border-radius:7px;
    border:0;
    font-size: 14px;
    display: inline-block;
  }

  .submit {
    display: inline-block;
    vertical-align: baseline;
    width: 40%;
    margin-left: -10px;
    padding: 12px 0;
  }
}

.copyright {
  margin-top: 40px;
  padding: 20px 0;
  background-color:darken( $primary-color, 5% ); 
  span {
    margin: 0 10px;
    color: white;
    font-size: 12px;
  }
  p, a {
    font-size: 12px;
    display: inline-block;
  }
}

.column, .columns {
  padding-left: 0.825rem;
  padding-right: 0.825rem;
  @include breakpoint(large) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
.about {
  .v-align-center {
    h1 {
      margin-bottom: 15px;
    }
    p {
      margin: 0;
    }
  }  
}



.mobile-order-1 {
  order: 1;
  @include breakpoint(large) {
    order: 2;
  }
}

.mobile-order-2 {
    order: 2;
  @include breakpoint(large) {
    order: 1;
  } 
}

.team {
  overflow: hidden;
}

.slide {
  position:relative;
  margin-right: 0;
  margin-left: 0;

  @include breakpoint(large) {
    margin-right: 10px;
    margin-left: 10px;
  } 

  &:hover {
    .bio {
      top: 0;

      &:after {
        opacity: 0.9;
      }
    }

    .hidden {
      opacity: 1;
    }
  }
}

.bio {
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);;
  position:relative;
  height: 100%;
  width: 100%;
  top: 84%;
  left: 0;
  padding: 20px;

  p {
    position:relative;
    z-index: 2;
    margin-bottom: 10px;
    font-size: 15px;
  }

  &:after {
    content: "";
    z-index: 1;    
    position:absolute;
    background-color:$primary-color;
    opacity: 0.9;
    top: 0;    
    left: 0;   
    width: 100%;
    height: 100%;

    @include breakpoint(large) {
      opacity: 1;
    }
  }

  @include breakpoint(large) {
    position:relative;    
  }

  .hidden {
    opacity: 1;
    @include breakpoint(large) {
      opacity: 0;
    }
  }
}

.slick-arrow {
  font-size: 0;
  line-height: 0;
  width: 14px;
  height: 29px;
  background: url('/assets/images/arrow.svg') center center no-repeat;
  background-size: 14px 29px;
  position:absolute;
  top: 50%;
  margin-top: - 7px;
}

.slick-prev {
  left: -5px;
  @include breakpoint(large) {
    left: -20px;
  }
}

.slick-next {
  transform: scaleX(-1);
  right: -5px;
  @include breakpoint(large) {
    right: -20px;
  }
}

.overflow {
  overflow: visible;
}

.project-wrap {
  position:relative;
  z-index: 4;

  .columns {
    padding:0;
    @include breakpoint(large) {
      padding-right: 10px;
    }
    &:nth-child(2) {
      padding: 0;

      @include breakpoint(large) {
        padding-right: 0;
        padding-left: 10px;        
      }
    }
  }
}

.off-grid {
  transform: translateY(0);

  @include breakpoint(large) {
    transform: translateY(-17%);    
  }
}

.allow-overflow {  
  overflow: initial;  
  margin: 0;
  padding-bottom: 0;

  @include breakpoint(large) {
    overflow: initial;  
    margin: 3% 0 0;
    padding-bottom: 3%;    
  }
}

.bio-select {
  a {
    display: block;
    text-align: center;    
    background-color:#4a6088;
    color: white;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    padding: 15px 5px;
    line-height: 1.2;
    span {
      display: block;
      font-size: 13px;
      font-weight: 600;
    }

    &.active, &:hover {
      background-color:$primary-color;
      border-right: 0;
    }
  }
}

.full-bio {
  padding: 30px;
  color: white;
  background-color:$primary-color;
  display: none;
  min-height: 194px;
  &.active {
    display: block;
  }
}

.underline {
  border-bottom: 1px solid $primary-color;
  padding-bottom:20px;
}

.inline-block {
  display: inline-block;
}

.services {
  .inline-block {
    // padding-left: 20px;
    // padding-right: 20px;
  }
}

.logo-shape {
  display: block;
  width: 100%;
  margin-top: -90px;
}

#map {
  width: 100%;
  height: 500px;
}


.popup {
  position:fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background-color:$primary-color;
  z-index: 99;

  &.active {
    display: flex;
  }

  .qr_code {
    width: 90%;
    max-width: 144px;

  }

  .close-popup {
    color: white;
    display: block;
    cursor: pointer;
    position:absolute;
    right: 15px;
    top: 12px;
    font-size: 30px;
    line-height: 1;
  }

}




.sutton {
  max-width: 120px;
  display: block;
}